import React from "react";
import './Businesstack.css';
import { IoRestaurantOutline } from "react-icons/io5";
import { CiShoppingBasket } from "react-icons/ci";
import { IoCutOutline } from "react-icons/io5";
import { IoCartOutline } from "react-icons/io5";
import { GiWeightLiftingUp } from "react-icons/gi";
import { MdPets } from "react-icons/md";
import { GiCakeSlice } from "react-icons/gi";
import { MdOutlineMovieFilter } from "react-icons/md";
import { LiaCarSideSolid } from "react-icons/lia";

export default function Businesstack(){


    return(
        <div class="industry-container">
        <h2>Which Business Owners Use This App?</h2>
        <div class="industry-list">
          <div class="industry-item">
            <CiShoppingBasket />
            <p>Retail</p>
          </div>
          <div class="industry-item">
            <IoRestaurantOutline />
            <p>Restaurant</p>
          </div>
          <div class="industry-item">
            <IoCutOutline />
            <p>Saloons</p>
          </div>
          <div class="industry-item">
            <IoCartOutline />
            <p>Grocery Stores </p>
          </div>
          <div class="industry-item">
            <GiWeightLiftingUp />
            <p>Fitness Centers</p>
          </div>
          <div class="industry-item">
            <MdPets />
            <p>Pet Services</p>
          </div>
          <div class="industry-item">
            <GiCakeSlice />
            <p>Bakeries</p>
          </div>
          <div class="industry-item">
            <MdOutlineMovieFilter />
            <p>Entertainment </p>
          </div>
          <div class="industry-item">
            <LiaCarSideSolid />
            <p>Travel Agencies </p>
          </div>
        </div>
      </div>
    )
}