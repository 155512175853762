import React from "react";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import './Footer.css';

export default function Footer(){

    return(
     <footer>
        <div className="footer-container">
            {/* <div className="footer-content" >
                <div className="footer-section">
                    <img className="logo" src="/images/happicount logo.jpeg" alt="happicount logo" loading="lazy" ></img>
                    <p>Calling all loyal customers! Get ready to unlock a world of exclusive offers and savings with Happicount - the ultimate app for rewarding your loyalty. Download Happicount today and start counting the stamps towards your next unbeatable offer!</p>
                </div>
                <div>
                    <h5>Categories</h5>
                    <p>Option 1</p>
                    <p>Option 2</p>
                    <p>Option 3</p>
                    <p>Option 4</p>
                </div>
                <div>
                    <h5>Quick Links</h5>
                    <p>About Us</p>
                    <p>Contact Us</p>
                </div>
            </div> */}
            <hr/>
            <div className="copyrightsection" >
                <div>
                    <p>© 2023 copyright all right reserved</p>
                </div>
                <div className="social-icons"> 
                    <FaFacebook className="social-icon" />
                    <FaLinkedin className="social-icon" />
                    <FaInstagramSquare className="social-icon" />
                </div>
            </div>
        </div>
     </footer>
    )
}