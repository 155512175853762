import React, { useState } from "react";
import './Navbar.css';
import { Link } from "react-router-dom";


export default function Navbar(){
    const [fix, setfix] = useState(false)

    const setFixed = ()=>{
        if(window.scrollY >= 2){
            setfix(true)
        }else{
            setfix(false)
        }
    }

    window.addEventListener("scroll", setFixed)
    return(
        
        <header  className={fix ? "headerfix" : "header"}  > 
        
            <Link to="/"><img className="logo" src="/images/happicount logo.jpeg" alt="happicount logo" loading="lazy" ></img></Link>

            {/* <input type="checkbox" id="menu-bar" />
            <label htmlFor="menu-bar"><i className="fa-solid fa-bars"></i></label>

            <nav className="navbar">
                <Link to="/">Home</Link>
                <a href="#">Services</a>
                <a href="#">Contact</a>
            </nav>
            */}
        </header>

    )
}