import React from "react";
import { MdInstallDesktop } from "react-icons/md";
import { FiCheckSquare } from "react-icons/fi";
import { FiLoader } from "react-icons/fi";


export default function Customer(){

    return(
        <div className="retailer_container" >
            <h3>Customer App</h3>
            <p>Install our mobile app from the Google play store or Apple Store & Register with your phone number.</p>
        <div className="s-box-container">
            <div className="s-box">
           
            <div className="bar"></div>
            
            <MdInstallDesktop className="icon" />
            
            <h1>1. Download & Install App</h1>
            
                <p>Install our mobile app from the Google play store or Apple Store & Register with your phone number.</p>
            
            
            </div>
                   
            <div className="s-box">
                <div className="bar"></div>
                <FiCheckSquare className="icon" />
                <h1>2. Register</h1>
                <p>Sign up with a few easy steps, creating your personalized account.</p>
            </div>
        
            <div className="s-box">
                <div className="bar"></div>
                <FiLoader className="icon" />
                <h1>3. Add Stamp</h1>
                <p>Every purchase adds up! Accumulate points or stamps for discounts, freebies, or exclusive deals.</p>
            </div>

        </div>
    </div>
    )
}