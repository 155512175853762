import React from "react";
import './Homesection.css';


export default function Homesection(){


    return(
        <>
           <section  className="downloadbgSection">
            
            <div className="downloadsbg" >
                <h2  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                    Download <span>Happicount</span> Now..!
                </h2>
                <p  data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
                Get rewards and perks everywhere with our Loyalty Card app! Whether you're shopping or grabbing a bite, enjoy the benefits with every purchase. Make your loyalty count across all your favorite places!
                </p>
                <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                    Available for both Android and IOS
                </p>

                <div className="round-shape-one" ></div>
                <div className="round-shape-two" ></div>
                <div className="round-shape-three" ></div>
                <img src="/images/shape-9.svg" alt="shape" className="shape-two" ></img>

                <div className="whysectionbtn">
                    <img src="./images/google-play.svg" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" style={{cursor: 'pointer'}} ></img>
                    <img src="./images/app-store.svg" style={{marginLeft: '12px',  cursor: 'pointer'}} data-aos="fade-up" data-aos-delay="350" data-aos-duration="1000"></img>
                </div>
            </div>
            <div>
                <img className="whysectionimg" src="images/get.png" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></img>

            </div>

        </section>

        </>
    )
}