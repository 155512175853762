import React from "react";
import './Whychooseus.css';
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlineInsights } from "react-icons/md";
import { FaChartBar } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";

export default function Whychooseus(){


    return(
        <>
            <div className="whychooseus_container" >
                <h2>Why Choose Us</h2>
                <p>Use our Loyalty Card Builder to create online digital<br/> mobile Loyalty Cards and combine loyalty rewards with our coupons.</p>
                <div className="whychoose_section" >
                    <div>
                        <img className="whysectionimg" src="images/get.png" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></img>
                    </div>
                    <div className="whychooseus_innersection_container" >
                        <div className="whychooseus_innersection">
                            <div>
                                <MdOutlinePayment className="whycooseus_icons"/>
                                <h3>Rewards</h3>
                                <p>Effortlessly merge loyalty perks and coupons for a powerful customer experience.</p>
                            </div>
                            <div>
                            <MdOutlineInsights className="whycooseus_icons second"/>
                                <h3>Gain Inweights</h3>
                                <p>Make informed decisions using our analytics for effective and strategic loyalty.</p>
                            </div>
                        </div>

                        <div className="whychooseus_innersection " >
                            <div>
                            <FaChartBar  className="whycooseus_icons third" />
                                <h3>Increase Sales</h3>
                                <p>Elevate revenue by turning shoppers into loyal customers with strategic loyalty incentives.</p>
                            </div>
                            <div>
                            <RiSecurePaymentLine className="whycooseus_icons fourth" />
                                <h3>Strategies</h3>
                                <p>Refine loyalty initiatives using insightful data for continuously improved business strategies.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}