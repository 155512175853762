import React, { useState } from "react";
import Retailer from "./Retailer";
import Customer from "./Customer";
import './Howitworks.css';
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";


export default function Howitworks(){

    const [activeTab, setActiveTab] = useState("tab1");

    return(
    <div className="howitworks_container" >
      <div className="seo_home_area" >
      <div>
        <h2>How it works</h2>
        <div className="Tabs">
          <ul className="nav">
              <TabNavItem title="Retailer" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
              <TabNavItem title="Customer" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
          </ul>
        <div className="outlet">
          
          <TabContent id="tab1" activeTab={activeTab}>
            <Retailer/>
          </TabContent>
          <TabContent id="tab2" activeTab={activeTab}>
            <Customer/>
          </TabContent>
      
        </div>
        </div>

        
          <div className="home_bubble" >
            <div className="bubble b_one"></div>
            <div className="bubble b_two"></div>
            <div className="bubble b_three"></div>
            <div className="bubble b_four"></div>
            <div className="bubble b_five"></div>
          </div>

        </div>

      </div>
    </div>
    )
}