import React from "react";
import './Infobar.css';


export default function Infobar(){

    return(
        <div className="infobar">
            <div>
                <h3>It's easy to get started</h3>
                <h4>And it’s FREE You have nothing to lose.</h4>
                <button class="button-18">Get started</button>
            </div>
        </div>
    )
}
