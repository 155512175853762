import React, { useEffect } from "react";
import './Blog.css'
import Bloglist from "./blogitem";


export default function Blog(){

    
    
    return(
        <>
        
        <Bloglist/>
       
        </>
    )
}