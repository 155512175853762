import React from "react";
import './Retailer.css';
import { MdInstallDesktop } from "react-icons/md";
import { FiCheckSquare } from "react-icons/fi";
import { FiLoader } from "react-icons/fi";


export default function Retailer(){

    return(
        <div>
            <div className="retailer_container" >
                <h3>Reatiler App</h3>
                <p>Install our mobile app from the Google play store or Apple Store & Register with your phone number.</p>
                <div className="s-box-container">
                      
                    <div className="s-box">
                   
                    <div className="bar"></div>
                    
                    <MdInstallDesktop className="icon" />
                    
                    <h1>1. Install App</h1>
                    
                        <p>Joining is quick and easy. Simply sign up for our Loyalty Card Program either online or in-store.</p>
                    
                    
                    </div>
                       	
                    <div className="s-box">
                        <div className="bar"></div>
                        <FiCheckSquare className="icon" />
                        <h1>2. Register</h1>
                        <p>Complete a straightforward registration process, providing essential details about your business.</p>
                    </div>
                
                    <div className="s-box">
                        <div className="bar"></div>
                        <FiLoader className="icon" />
                        <h1>3. Add Store</h1>
                        <p>Integrate your business into our loyalty card network, becoming visible to a wider audience of potential customers.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}