import React, { useState } from 'react';
import './requestdelete.css';
import axios from 'axios';

const Requestretailerdelete = () => {

    const apiURL = "http://ec2-43-205-211-117.ap-south-1.compute.amazonaws.com"


    const [mobilenumber, setMobileNumber] = useState('');
    const [password, setPassword] = useState("");

    const [success, setSuccess]  = useState(false);
    // const [error, setError]  = useState(false);
    const [errormsg, setErrorMsg]  = useState("");

      const deleteRetailer = async () => {
        if (mobilenumber.length < 10) {
            setErrorMsg("Mobile Number should be at least 10 characters");
            setTimeout(() => {
                setErrorMsg('');
            }, ((1000)));
            return;
          }
        const body ={
            mobilenumber: mobilenumber,
            password: password
        }
        try {
          const res = await axios.post(`${apiURL}/retailer/accountdeleterequest`, body);
          if(res.status === 200){
            setSuccess(true);
            setMobileNumber("");
            setPassword("");
          }

        } catch (err) {
          console.log("retailer reason err", err);

            // console.log("err", err.response.data.error === "Bad username or password");
            if(err.response && err.response.data.error === "Bad username or password"){
                setErrorMsg("Incorrect Mobile Number or Password");
            }else{
              setErrorMsg("Something went wrong try again");
            }
        }
        setTimeout(() => {
            setErrorMsg('');
            setSuccess(false);
        }, ((1500)));
      }


  return (
    <div className='form-container' >
    <div  className="login-container">
    <input type="checkbox" id="flip" />
    <div className="cover">
      <div className="front">
        <img src="/images/pexels.jpg" alt="" />
        {/* <div className="text">
          <span className="text-1">Happy Count <br/></span>
          <span className="text-2">Let's get connected</span>
        </div> */}
      </div>
      <div className="back">
        <img className="backImg" src="images/pexels.jpg" alt="" />
        {/* <div className="text">
          <span className="text-1">Happy Count <br/> </span>
          <span className="text-2">Let's get started</span>
        </div>   */}
      </div>
    </div>
    <div className="forms">
        <div className="form-content">
          <div className="login-form">
            <div className="title">Retailer</div>
            <p className='info'>Your account will be deleted after the request accepted</p>
         
            <div className="input-boxes">
              <div className="input-box">
                <i className="fas fa-envelope"></i>
                <input type="text" placeholder="Enter your Mobile Number" value={mobilenumber} required onChange={(e) => setMobileNumber(e.target.value)} />
              </div>
              <div className="input-box">
                <i className="fas fa-lock"></i>
                <input type="password" placeholder="Enter your password" value={password} required onChange={(e) => setPassword(e.target.value)} />
              </div>
              {/* <div className="text"><a href="#">Forgot password?</a></div> */}
              <div className="button input-box">
                <input type='button' value="Submit Request" onClick={deleteRetailer}/>
              </div>
              <p className='success'>{success && "Account Deleted successfully"}</p>
              <p className='error'>{ errormsg && errormsg }</p>
            {/* <div className="text sign-up-text">Are you an customer? <label htmlFor="flip" >Click here</label></div> */}
            </div>
      </div>
    </div>
    </div>
  </div>
  </div>

  );
};

export default Requestretailerdelete;