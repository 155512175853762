import React from "react";
import Layout from "./layout/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import Blogviewpage from "./pages/blogviewpage/blogviewpage";
import Privacypolicy from "./pages/privacypolicy/Privacypolicy";
import Requestdelete from "./pages/requestdelete/requestdelete";
import Requestcustomerdelete from "./pages/requestdelete/requestcustomerdelete";
import Requestretailerdelete from "./pages/requestdelete/requestretailerdelete";

function App() {
  return (
    <>
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={ <Homepage/> } />
          <Route path='/blog/:id' element={ <Blogviewpage/> } />
          {/* <Route path='/delete_account' element={ <Requestdelete/> } /> */}
          <Route path='/customer_delete_account' element={ <Requestcustomerdelete/> } />
          <Route path='/retailer_delete_account' element={ <Requestretailerdelete/> } />
          <Route path='/privacypolicy' element={ <Privacypolicy/> } />
        </Routes>
      </Layout>
    </Router>
    </>
  );
}

export default App;
