import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer.js/Footer";
import Backtotop from "../components/backtotop/Backtotop";

export default function Layout({children}){

    return(
        <>
            <Navbar/>
            <main>
                {children}
            </main>
            <Footer/>
            <Backtotop/>
        </>
    )
}