import React from "react";
import Homesection from "../../components/homesection/Homesection.js";
import Howitworks from "../../components/howitworks/Howitworks.js";
import Whychooseus from  "../../components/whychooseus/Whychooseus.js";
import Blog from "../../components/blog/Blog.js";
import Infobar from "../../components/infobar/Infobar.js";
import Businesstack from "../../components/businesstack/Businesstack.js";

export default function Homepage(){

    return(
        <>
            <Homesection/>
            <Howitworks/>
            <Whychooseus />
            <Blog/>
            <Businesstack />
            <Infobar />
        </>
    )
}