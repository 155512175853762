import React from "react";
import './Blog.css';
import { datas } from "../../blogdata";
import { Link } from "react-router-dom";



export default function Bloglist(){



    return(
        <>
        <h2 className="blog_head">Our blogs</h2>
        <div class="blog_container"  >
            {
                datas.map((data)=>{
                    
                return(
            
                    data.id >= 1 && data.id <=3 && 
                    <div class="card">
                        <div class="card-header">
                        <img src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg" alt="rover" />
                        </div>
                        <div class="card-body">
                        <span class="tag tag-teal"> {data.tag}</span>
                        <h4>
                            {data.title}
                        </h4>
                        <p>
                            {data.intro}<br/>
                            <Link to={`/blog/${data.title.toString().replace(/[^a-zA-Z0-9 ]/, '')}` }>Read More...</Link>
                        </p>
                        {/* <div class="user">
                            <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                            <div class="user-info">
                            <h5>{data.date}</h5>
                            <small>2h ago</small>
                            
                            </div>
                        </div> */}
                        </div>
                    </div>
                
                
                )
                
            })
        }
        </div>
   
        </>
    )
}
